<template lang="pug">
.main-wrapper.unidades-listar
		header.main-wrapper-header
			.p-grid.p-align-center
				.p-col-12.ta-right
					h1.text-header.text-secondary MedClub / <b>Empresas</b>

		Dialog(:header="'Cancelar Atualização de Rendimento'" :visible.sync='dialogCancelar' :modal='true')
			.p-grid.p-fluid.p-align-center
				.p-col-12.ta-center
					label.form-label Deseja realmente cancelar?
				.p-col-6.ta-center
					ProgressBar(v-if='waitingCancelar' strokeWidth='6' mode='indeterminate')
					Button.p-button-raised.p-button-rounded.p-button-danger.mr-1(
							v-else
							label="Sim"
							@click="cancelarAtualizacao(dialogCancelar_id, 'C')"
						)
				.p-col-6.ta-center
					Button.p-button-raised.p-button-rounded.mr-1(
							label="Não"
							@click="dialogCancelar = false"
						)

		Dialog.dialogRendimento(:visible.sync='dialogRendimento' :modal='false')
			.p-grid.p-fluid.p-align-center
				.p-col-4(style="margin-top: -14px")
					h3 {{dialogRendimento_data.nm_fantasia}}
				p Rendimento de 
				.p-col-2.ta-center
					Calendar.FirstCalendar(
						view="month"
						dateFormat="mm/yy"
						v-model='data_inicio'
						:yearNavigator="true"
						yearRange="2000:2030"
						:locale="pt_br"
						appendTo="body"
					)
				p até
				.p-col-2.ta-center
					Calendar(
						view="month"
						dateFormat="mm/yy"
						v-model='data_fim'
						:yearNavigator="true"
						yearRange="2000:2030"
						:locale="pt_br"
						appendTo="body"
					)
				.p-col-2.ta-left
					Button.p-button-raised.p-button-primary.mr-1(
								v-tooltip.top="'Rendimento'"
								label="Atualizar"
								icon="jam jam-refresh"
								@click="openDialogRendimento(dialogRendimento_data);"
							)
			.p-grid.p-fluid.p-align-center
				.p-col-12.ta-center
					ProgressBar(v-if='waitingHistorico' strokeWidth='6' mode='indeterminate')
					div(v-else)
						div.chart-container
							Panel(header='Gráfico' toggleable style='margin-bottom: 10px;')
								h3 {{ dialogRendimento_data.nm_fantasia }}: Histórico de Rendimentos
								Chart.chart(type="line" :data="historyData" :options="chart_options")
								
								h3 Saldo atual ({{ formatToBRL(rendimento.ultimo_saldo - ultimo_rendimento) }}) + último rendimento ({{ formatToBRL(ultimo_rendimento) }}):
								b {{ formatToBRL(rendimento.ultimo_saldo) }}
										
						Panel(header='Detalhes' toggleable :collapsed="true")
							DataTable(:value="rendimento_atts" :expandedRows.sync="expandedRows")
								Column(:expander="true" :headerStyle="{'width': '3rem'}")
								Column(field='atualizacao_id' header='ID da Atualização')
								Column(field='cd_empresa' header='ID da Empresa')
								Column(field='competencia' header='Comptetência')
									template(#body='props')
										p {{ formatDateToMMYYYY(props.data.competencia) }}
								Column(field='saldo' header='Saldo')
									template(#body='props')
										p {{ formatToBRL(props.data.saldo) }}
								Column( header='Rendimento Acumulado')
									template(#body='props')
										p {{ formatToBRL(props.data.rendimento_acumulado) }}
								Column(field='liquido' header='Rendimento Mensal')
									template(#body='props')
										p {{ formatToBRL(props.data.liquido) }}
								Column(headerStyle='text-align: center')
									template(#header) Ações
									template(#body='props')
										.ta-center
											div(v-if="checkStatus(props.data, 'C')")
												p.status_tag Cancelado
											Button(v-else).p-button-raised.p-button-rounded.p-button-warning.mr-1(
												label="Cancelar"
												icon="jam jam-trash"
												@click="openDialogCancelar(props.data.atualizacao_id)"
											)
								template(#expansion="rendimento_atts")
									div(class="orders-subtable")
										DataTable(:value="rendimento_atts.data.debito_credito_array")
											Column(field="cd_paciente.nm_pessoa_fisica" header="Correntista")
											Column(field="dt_data" header="Data")
												template(#body='props')
													p {{ formatISOToBRDateTime(props.data.dt_data) }}
											Column(field="ds_informacoes" header="Informações")
											Column(field="ie_status" header="Status")
											Column(field="nr_valor" header="Valor")
												template(#body='props')
													p {{ formatToBRL(props.data.nr_valor) }}
			Panel(header="Adicionar")
				form(@submit.prevent='handleSubmitRendimento(dialogRendimento_data.id, rendimento)')
					.p-grid.p-fluid.p-align-end
						.p-col-12.p-md-2
							label.form-label Código da empresa:
							InputText(type='text' v-model='dialogRendimento_data.id' readonly='true' disabled='true')
						.p-col-12.p-md-2
							label.form-label Competência:
							Calendar(
								view="month"
								dateFormat="mm/yy"
								v-model='rendimento.competencia'
								:yearNavigator="true"
								yearRange="2000:2030"
								:locale="pt_br"
								required
								appendTo="body"
								@input='getUtilizacoes(rendimento.competencia, dialogRendimento_data.id)'
							)
						.p-col-12.p-md-2
							label.form-label Aplicação:
							CurrencyInput(
								v-model='rendimento.aplicacao'
								locale='pt-BR'
								:auto-decimal-mode='true'
								:value-range='{ min: 0 }'
								required)
						.p-col-12.p-md-1
							br
						.p-col-12.p-md-2
							label.form-label Utilização:
							ProgressBar(v-if='waitingUtilizacoes' strokeWidth='6' mode='indeterminate')
							CurrencyInput(
								v-else
								v-model='rendimento.utilizacao'
								locale='pt-BR'
								:auto-decimal-mode='true'
								:value-range='{ min: 0 }'
								required)
						.p-col-12.p-md-2
							label.form-label Saída:
							CurrencyInput(
								v-model='rendimento.saida'
								locale='pt-BR'
								:auto-decimal-mode='true'
								:value-range='{ min: 0 }'
								required)
					.p-grid.p-fluid.p-align-end
						.p-col-12.p-md-2
							label.form-label Rendimento Acumulado:
							CurrencyInput(
								v-model='rendimento.rendimento_acumulado'
								locale='pt-BR'
								:auto-decimal-mode='true'
								:value-range='{ min: 0 }'
								required)
						.p-col-12.p-md-2
							label.form-label Rendimento Mensal:
							CurrencyInput(
								v-model='rendimento.liquido = rendimento.rendimento_acumulado - ultimo_rendimento_acumulado'
								locale='pt-BR'
								:auto-decimal-mode='true'
								:value-range='{ min: 0 }'
								disabled
								required)
						.p-col-12.p-md-2
							label.form-label Investimento Mensal:
							CurrencyInput(
								disabled
								v-model='rendimento.investimento_mensal = rendimento.aplicacao + rendimento.rendimento_acumulado'
								locale='pt-BR'
								:auto-decimal-mode='true'
								:value-range='{ min: 0 }'
								required)
					.p-grid.p-fluid.p-align-end
						.p-col-12.p-md-4
							br
						.p-col-12.p-md-2
							label.form-label Saldo atual:
							CurrencyInput(
								disabled
								v-model='rendimento.novo_liquido = rendimento.ultimo_saldo + rendimento.investimento_mensal - rendimento.utilizacao - rendimento.saida - ultimo_rendimento_acumulado'
								locale='pt-BR'
								:auto-decimal-mode='true'
								:value-range='{ min: 0 }'
								required)
						
						.p-col-2.p-fluid
							ProgressBar(v-if='waitingForm || waitingHistorico' strokeWidth='6' mode='indeterminate')
							Button.p-button-success(v-else label='Salvar' icon='jam jam-plus')
						.p-col-2.p-fluid
							ProgressBar(v-if='waitingForm || waitingHistorico' strokeWidth='6' mode='indeterminate')
							Button.p-button-warning(v-else label='Zerar Campos' icon='jam jam-refresh-reverse' @click="clearFields()" type="button")


		Dialog.dialogApagar(header='Remover Empresa' :visible.sync='dialogApagar' :modal='true')
			p Deseja remover a unidade <b>{{ dialogApagar_data.nm_unidade }}</b>?
			.ta-center.my-4
				ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
				Button.p-button-danger(v-else label='Remover' @click='remove()')

		Dialog.dialogSuspender(header='Suspender Empresa' :visible.sync='dialogSuspender' :modal='true')
				.p-grid
					.p-col-12
						label.form-label Motivo da suspensão da empresa <b>{{ dialogSuspender_data.nm_fantasia }}</b>
						textarea.textarea-motivo(
							v-model='ds_motivo_suspensao'
							style='width: 100%; padding: 5px'
							:autoResize="true"
							rows="5" 
							placeholder='Digite o motivo da suspensão.'
						)
					ProgressSpinner(v-if='waitingToggleStatus' strokeWidth='6' style="height: 25px")
					.p-col-12.ta-center(v-else)
						Button.p-button-danger(label='Suspender' @click='toggleStatus(dialogSuspender_data.id)')
						Button.p-button.ml-4(label='Abortar' @click='closeDialogSuspender()')
				
		Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' mode="indeterminate")
			.p-grid.p-fluid
				.p-col-12.p-md-4
					label.form-label Empresa:
					.p-inputgroup
						InputText(placeholder='Nome Fantasia, Razão Social ou CNPJ'
							@keyup.enter.native='applyFilters()'
							@input="checkEmpty('nm_empresa')"
							v-model='filters.nm_empresa'
							v-tooltip.top.focus="'Aperte ENTER para pesquisar'"
						)
						Button(icon='jam jam-search' @click='applyFilters()')

				.p-col-12.p-md-4
					label.form-label Correntista:
					.p-inputgroup
						InputText(placeholder='Nome ou CPF'
							@keyup.enter.native='applyFilters()'
							@input="checkEmpty('nm_correntista')"
							v-model='filters.nm_correntista'
							v-tooltip.top.focus="'Aperte ENTER para pesquisar'"
						)
						Button(icon='jam jam-search' @click='applyFilters()')
				.p-col-12.p-md-4
					label.form-label <br>
					Button.p-button-primary(label='Limpar filtros' icon='jam jam-rubber' @click='clearFilters()')

		ProgressBar(v-if='waiting' mode="indeterminate")
		div(v-else-if='list.length == 0')
			p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
		div(v-else)
			DataView.dataview.my-2(:value="list" layout="grid")
				template(#grid="props")
					.p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
						Panel.panel-list.ta-center(:header="props.data.nm_fantasia" style='position: relative')
							.ta-left
								p <b>Nome Fantasia:</b> {{ props.data.nm_fantasia }}

			Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

			Panel.datatable(header='Lista de Empresas')
				Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
				DataTable(:value="list")
					Column(field='nm_fantasia' header='Nome Fantasia')
					Column(field='nm_razao_social' header='Razão social')
					Column(bodyClass="ta-center" field='nr_cnpj' header='CNPJ')
					Column(headerStyle='text-align: center')
						template(#header) Tipo
						template(#body='props')
							.ta-center
								p {{ props.data.tipo_empresa }}
					Column(headerStyle='text-align: center')
						template(#header) Ações
						template(#body='props')
							.ta-center
								Button.p-button-raised.p-button-rounded.p-button-warning.mr-1(
									v-tooltip.top="'Rendimento'"
									icon="jam jam-coin"
									@click="openDialogRendimento(props.data);"
								)
								Button.p-button-raised.p-button-rounded.mr-1(
									v-tooltip.top="'Visualizar'"
									icon="pi pi-external-link"
									@click="$router.push(`/${isAdm? 'med-empresa-empresas-adm': 'med-empresa-empresas'}/visualizar/${ props.data.id }/`)"
								)
					
								Button.p-button-raised.p-button-rounded.p-button-danger(
									v-if="!props.data.ie_status && isAdm"
									v-tooltip.top="'Clique para revogar a suspensão.'"
									v-model="props.data.ie_status"
									onLabel="Ativa"
									icon="pi pi-times"
									@click="toggleStatus(props.data.id)"
								)
								Button.p-button-raised.p-button-rounded.p-button-success(
									v-else-if="isAdm"
									v-tooltip.top="'Clique para suspender.'"
									v-model="props.data.ie_status "
									onLabel="Ativa"
									offLabel="Suspensa"
									icon="pi pi-check"
									@click="openDialogSuspender(props.data)"
								)
				Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
	.unidades-listar {
		.dataview {
			@media all and (min-width: 577px) {
				display: none;
			}
		}
		.datatable {
			@media all and (max-width: 576px) {
				display: none;
			}
			.cell {
				padding: 16px 0;
				text-align: center;
				&.ex  {  background-color: #e4f8e1; }
				&.a  { background-color: #faf3dd; }
			}
		}
		.disabled {
			display: none;
		}
		.dialogRendimento {
			height: 100%;
		}
		.dialogApagar {
			.p-progress-spinner {
				width: 29px;
				height: auto;
			}
		}
		.dialogSuspender {
			width: 35vw;
		}
		.textarea-motivo {
			height: 200px !important;
			overflow-y: auto !important;
			resize: none;
		}
		.chart-container {
			.chart {
				max-width: 60%;
				margin-left: 20%;
				margin-bottom: 15px;
			}
		}
		.status_tag {
			background-color: rgb(202, 43, 43);
			padding: 5px;
			border-radius: 15px;
			color: white;
		}
	}
	.p-tooltip {
		max-width: none;
	}
</style>

<script>
	import ProgressBar from 'primevue/progressbar'
	import DataView from 'primevue/dataview'
	import Dropdown from 'primevue/dropdown'
	import Panel from 'primevue/panel'
	import Paginator from 'primevue/paginator'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
	import Button from 'primevue/button'
	import Tooltip from 'primevue/tooltip'
	import Dialog from 'primevue/dialog'
	import InputText from 'primevue/inputtext'
	import ProgressSpinner from 'primevue/progressspinner'
	import ToggleButton from 'primevue/togglebutton';
	import { Empresa } from './../../middleware'
	import wsConfigs from './../../middleware/configs'
	import Calendar from 'primevue/calendar';
	import InputNumber from 'primevue/inputnumber';
	import CurrencyInput from "@/components/CustomComponents/CurrencyInput";
	import { pCalendarLocale_ptbr } from './../../utils';
	import Chart from 'primevue/chart';
	import InputSwitch from 'primevue/inputswitch';
	export default {
		created () {
			this.applyFilters()
			this.getTiposEmpresa()
			if(this.$route.path.includes("med-empresa-empresas-adm")) this.isAdm = true;
			// console.log(this.isAdm)

		},
		components: { ProgressBar, DataView, Dropdown, Panel, Paginator, DataTable,
			Column, Button, Tooltip, Dialog, ProgressSpinner, InputText, ToggleButton,
			Calendar, InputNumber, CurrencyInput, Chart, InputSwitch },
		directives: { tooltip: Tooltip },
		watch: {
			'$route.path': function(){
			this.$router.go(0) 
			}
		},
		data () {
			return {
				list: [],
				isAdm: false,
				windowInnerWidth: window.innerWidth,
				waitingForm: false,
				waiting: false,
				waitingCancelar: false,
				waitingApagar: false,
				waitingUtilizacoes: false,
				dialogApagar: false,
				dialogRendimento: false,
				dialogCancelar: false,
				dialogCancelar_id: null,
				waitingToggleStatus: false,
				waitingHistorico: false, 
				dialogApagar_data: {},
				dialogRendimento_data: {},
				dialogSuspender: false,
				expandedRows: [],
				year: null,
				ano_vigente: null,
				data_inicio: new Date(new Date().getFullYear(), 0, 1),
				data_fim: new Date(new Date().getFullYear(), 11, 31),
				dialogSuspender_data: {},
				ds_motivo_suspensao: '',
				filters: {
					nm_empresa: '',
					nm_correntista: '',
					cd_tipo_empresa: 2
				},
				options: { tiposEmpresa: [] },
				paginator: {
					page: this.$route.query.pg ? this.$route.query.pg : 1,
					per_page: wsConfigs.paginator_perPage,
					count: 0
				},
				rendimento: {
					ultimo_saldo: 0,
					investimento_mensal: 0,
					utilizacao: 0,
					saida: 0,
					aplicacao: 0,
					liquido: 0,
					novo_liquido: 0,
					rendimento_acumulado: 0
				},
				ultimo_rendimento: 0,
				ultimo_rendimento_acumulado: 0,
				pt_br: pCalendarLocale_ptbr,
				historyData: {},
				rendimento_atts: [],
				chart_options: {
					responsive: true,
					hoverMode: 'index',
					stacked: false,
					scales: {
						yAxes: [{
							type: 'linear',
							display: true,
							position: 'left',
							id: 'y-axis-1',
						}]
					}
				}
			}
		},
		methods: {
			getUtilizacoes(competencia, empresa_id){
				let mes = ('0' + (competencia.getMonth() + 1)).slice(-2);
				let ano = competencia.getFullYear();
				let mesAno = `'${mes}-${ano}'`;
				this.waitingUtilizacoes = true;
				Empresa.getUtilizacoes(empresa_id, { mes: mesAno, cd_estabelecimento: 128 }).then(response => {
					this.waitingUtilizacoes = false;
					if (response.status === 200) {
						let details = response.data.detail;
						let total_nr_valor = details[details.length - 1].total_nr_valor;
						this.rendimento.utilizacao = total_nr_valor;
					}
				});
			},
			cancelarAtualizacao(id, status){
				this.waitingCancelar = true
				Empresa.statusAtualizacaoRendimento({"id": id, "ie_status": status}).then(response => {
					this.waitingCancelar = false
					if (response.status === 200) {
						this.dialogCancelar = false;
						this.openDialogRendimento(this.dialogRendimento_data)
					}
				})
			},
			openDialogCancelar(id){
				this.dialogCancelar_id = id;
				this.dialogCancelar = true;
			},
			checkStatus(dados, status) {
				let db_array = dados.debito_credito_array;
				let todosC = db_array.every(item => item.ie_status === status);
				return todosC;
			},
			formatISOToBRDateTime(isoString) {
				const isoPattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}([+-]\d{2}:\d{2})$/;
				if (!isoPattern.test(isoString)) {
					throw new Error("Formato de data e hora inválido. Use 'yyyy-mm-ddThh:mm:ss.000000-03:00'.");
				}
			
				const [datePart, timePart] = isoString.split('T');
				const [year, month, day] = datePart.split('-');
				const time = timePart.split('.')[0];
			
				return `${day}/${month}/${year} ${time}`;
			},
			formatDateToMMYYYY(dateString) {
				const datePattern = /^\d{4}-\d{2}-\d{2}$/;
				if (!datePattern.test(dateString)) {
					throw new Error("Formato de data inválido. Use 'yyyy-mm-dd'.");
				}
				const [year, month] = dateString.split('-');
				
				return `${month}/${year}`;
			},
			formatToBRL(value) {
				const number = parseFloat(value);
				if (isNaN(number)) {
					throw new Error("Invalid number");
				}
				return new Intl.NumberFormat('pt-BR', {
					style: 'currency',
					currency: 'BRL'
				}).format(number);
			},
			handleSubmitRendimento(id, obj){
				obj['cd_empresa'] = id;
				this.waitingForm = true;
				Empresa.salvarRendimento(obj).then(response => {
					this.waitingForm = false;
					if (response.status === 200) {
						this.rendimento = {
							ultimo_saldo: 0,
							investimento_mensal: 0,
							utilizacao: 0,
							saida: 0,
							aplicacao: 0,
							liquido: 0,
							novo_liquido: 0,
							rendimento_acumulado: 0,
						};
						this.openDialogRendimento(this.dialogRendimento_data);
						this.atualizar_saldo_atual()
					}
				})
			},
			checkEmpty (prop) {
				if (! this.filters[prop]) this.applyFilters()
			},
			getTiposEmpresa() {
				this.waitingTipoEmpresa = true
				Empresa.getTiposEmpresa().then(response => {
					this.waitingTipoEmpresa = false
					if (response.status === 200) {
						this.options.tiposEmpresa = response.data.map(tipo => {
							return { value: tipo.id, text: tipo.ds_tipo_empresa }
						})
						this.options.tiposEmpresa.unshift({ value: null, text: "TODOS"})
					}
				})
			},
			getList (params) {
				this.waiting = true
				return Empresa.findAll(params).then(response => {
					if (response.status == 200) {
						this.paginator.count = response.data.count
						response.data.results.forEach(empresa => {
							if (empresa.nr_cnpj) empresa.nr_cnpj_f = empresa.nr_cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
						})
						this.list = response.data.results
					}
					this.waiting = false
					return true
				})
			},
			applyFilters () {
				let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
				if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
				Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key]  })
				this.getList(params)
			},
			onPage (ev) {
				this.paginator.page = ev.page + 1
				this.applyFilters()
			},
			remove () {
				this.waitingApagar = true
				Empresa.remove(this.dialogApagar_data.id).then(response => {
					this.waitingApagar = false
					if (([200, 201, 204]).includes(response.status)) {
						this.$toast.info('Empresa removida com sucesso', { duration: 3000 })
						this.dialogApagar = false
						this.applyFilters()
					} else if (response.data.detail) {
						if (typeof response.data.detail == 'string')
							this.$toast.error(response.data.detail, { duration: 3000 })
						else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
					}
				})
			},
			closeDialogRendimento(){
				window.stop();
				this.dialogRendimento = false;
				this.dialogRendimento_data = {};
				this.rendimento = {};
			},
			atualizar_saldo_atual() {
				if (this.rendimento_atts.length > 0) {
					for (let i = this.rendimento_atts.length - 1; i >= 0; i--) {
						if (this.rendimento_atts[i].debito_credito_array[0].ie_status != "C") {
							this.rendimento.ultimo_saldo = this.rendimento_atts[i].saldo;
							break;
						}
					}
				}
			},
			openDialogRendimento(empresa_data) {
				this.dialogRendimento = true;
				this.dialogRendimento_data = empresa_data;
				this.waitingHistorico = true;
				this.rendimento_atts = [];
				this.historyData = {};
				this.rendimento = {
					ultimo_saldo: 0,
					investimento_mensal: 0,
					utilizacao: 0,
					saida: 0,
					aplicacao: 0,
					liquido: 0,
					novo_liquido: 0,
					rendimento_acumulado: 0,
				};

				const formatDate = (date) => {
					let year = date.getFullYear();
					let month = ('0' + (date.getMonth() + 1)).slice(-2);
					let day = ('0' + date.getDate()).slice(-2);
					return `${year}-${month}-${day}`;
				};
			
				let formattedDataInicio = formatDate(new Date(this.data_inicio));
				let formattedDataFim = formatDate(new Date(this.data_fim));

				Empresa.getRendimento(empresa_data.id, {
					chart: true,
					data_inicio: formattedDataInicio,
					data_fim: formattedDataFim
				}).then(response => {
					this.waitingHistorico = false;
					this.rendimento_atts = response.data.atualizacoes;
					this.historyData = response.data.hist_chart_data;

					this.ultimo_rendimento = 0;
					this.ultimo_rendimento_acumulado = 0;

					// Itera de trás para frente para encontrar a última atualização com todos os status "A"
					for (let i = response.data.atualizacoes.length - 1; i >= 0; i--) {
						const atualizacao = response.data.atualizacoes[i];
						const allStatusA = atualizacao.debito_credito_array.every(debito_credito =>
							debito_credito.ie_status === "A"
						);
						if (allStatusA) {
							this.ultimo_rendimento = atualizacao['liquido'];
							this.ultimo_rendimento_acumulado = atualizacao['rendimento_acumulado'];
							break;  // Sai do loop assim que encontrar a primeira atualização válida
						}
					}

					// for (let i = response.data.atualizacoes.length - 1; i > 0; i--) {
					// 
					// 	const atualizacao_atual = response.data.atualizacoes[i];
					// 	const allStatusA_atual = atualizacao_atual.debito_credito_array.every(debito_credito =>
					// 		debito_credito.ie_status === "A"
					// 	);
					// 
					// 	for (let j = i - 1; j >= 0; j--) {
					// 	
					// 		const atualizacao_anterior = response.data.atualizacoes[j];
					// 		const allStatusA_anterior = atualizacao_anterior.debito_credito_array.every(debito_credito =>
					// 			debito_credito.ie_status === "A"
					// 		);
					// 	
					// 		if (allStatusA_atual && allStatusA_anterior) {
					// 			response.data.atualizacoes[i].rendimento_acumulado = response.data.atualizacoes[i].liquido + response.data.atualizacoes[j].liquido
					// 			continue
					// 		}
					// 		if (allStatusA_atual) {
					// 			response.data.atualizacoes[i].rendimento_acumulado = response.data.atualizacoes[i].liquido
					// 		}
					// 	}
					// }

					this.atualizar_saldo_atual();
				});

			},
			openDialogSuspender(empresa_data){
				this.dialogSuspender = true;
				this.dialogSuspender_data = empresa_data;
			},
			closeDialogSuspender(){
				this.dialogSuspender = false;
				this.dialogSuspender_data = {};
				this.ds_motivo_suspensao = '';
			},
			toggleStatus(id){
				// console.log(this.ds_motivo_suspensao )
				if(this.ds_motivo_suspensao == '' && this.list.find(item => item.id == id).ie_status) {
					this.$toast.error('Diga o motivo da suspensão.', { duration: 3000 })
					return
				}
				this.waitingToggleStatus = true
				let data = { "ds_motivo": this.ds_motivo_suspensao }
				Empresa.toggle_status(id, data).then(response => {
					this.waitingToggleStatus = false
					this.closeDialogSuspender()
					if (([200, 201, 204]).includes(response.status)) {
						this.$toast.info('Status alterado', { duration: 3000 })
						this.ds_motivo_suspensao = '';
						this.applyFilters()
					} else if (response.data.detail) {
						if (typeof response.data.detail == 'string')
							this.$toast.error(response.data.detail, { duration: 3000 })
						else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
					}
				})
			},
			clearFields(){
				this.rendimento = {
					ultimo_saldo: 0,
					investimento_mensal: 0,
					utilizacao: 0,
					saida: 0,
					aplicacao: 0,
					liquido: 0,
					novo_liquido: 0,
					rendimento_acumulado: 0,
				};
			}
		}
	}
</script>
